<template>
  <div class="mt-1">
    <div class="parent">
      <div class="banner d-flex">
        <a class="btn btn-link btn-sm" style="color: #ffffff" @click="goBack()">
          <svg fill="#fff" class="material-design-icon__svg" width="15" height="15" viewBox="0 0 24 24">
            <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z">
            </path>
          </svg>
          <span> Go Back</span>
        </a>

        <div class="title_name w-100 text-center" style="margin-top:0px;margin-left: 5%;">
          <h6 class="mb-0 pt-1">FootballX</h6>
        </div>

        <div class="title_name w-100 text-center" style="margin-top:0px">
          <h6 style="float: right;margin-right: 10px;" class="mb-0 p-2">
            <a class="header-join-button small-text" style="color: #000 !important;margin-top: 9px;" data-toggle="modal"
              data-target="#accountTopUp">
              Deposit
            </a>
          </h6>
        </div>

        <div class="modal fade" id="accountTopUp" tabindex="-1" role="dialog" aria-labelledby="accountTopUpLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog" role="document">
            <div style="background-color: white;color: black;" class="modal-content">

              <div class="modal-header">
                <h5 class="modal-title text-dark" id="exampleModalLabel">Deposit</h5>
                <span type="button" class="close" data-dismiss="modal">&times;</span>
              </div>
              <div class="modal-body">
                <section class="px-2 mb-3">
                  <div class="form-wrapper1 s-a-1 mb-0">

                    <div class="mb-0">
                      <input
                        style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 30px;text-align: center;"
                        type="number" name="amount" id="deposit" placeholder="10" min="0" class="form-control"
                        value="99" aria-label="Amount (to the nearest dollar)" v-model="amount" />
                    </div>
                    <br />
                    <div class="d-flex justify-content-between mb-2">
                      <div class="depo-btn-wrapper">
                        <button style="background-color: var(--darkBackground);color: var(--white);" class="btn odd-btn px-2"
                          @click="setAmount(20)">
                          +<span class="deposit_val">20</span>
                        </button>
                      </div>
                      <div class="depo-btn-wrapper">
                        <button style="background-color: var(--darkBackground);color: var(--white);" class="btn odd-btn px-2"
                          @click="setAmount(50)">
                          +<span class="deposit_val">50</span>
                        </button>
                      </div>
                      <div class="depo-btn-wrapper">
                        <button style="background-color: var(--darkBackground);color: var(--white);" class="btn odd-btn px-2"
                          @click="setAmount(100)">
                          +<span class="deposit_val">100</span>
                        </button>
                      </div>
                      <div class="depo-btn-wrapper">
                        <button style="background-color: var(--darkBackground);color: var(--white);" class="btn odd-btn px-2"
                          @click="setAmount(500)">
                          +<span class="deposit_val">500</span>
                        </button>
                      </div>
                    </div>
                    <small style="color: var(--darkBackground);" class="s-a-1-txt mt-3 mb-3">Minimum Deposit amount: Ksh 10</small>
                    <button style="background-color: var(--darkBackground);color: var(--white);" @click="deposit"
                      id="deposit-profile" v-bind:class="loading" class="copy-betslip-btn1 py-2 form-control">
                      Deposit
                    </button>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <iframe allow="fullscreen" v-show="isLoaded" class="iframe-container" v-bind:src="launchURL" allowfullscreen
        webkitallowfullscreen></iframe>
      <div class="banner d-flex">
        <p class="btn btn-link btn-sm" style="color: #ffffff">
          <span> FootballX Page</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import prag from "@/services/prag";
import stp from "@/services/stp";
import elbet from "@/services/elbet";
import smart from "@/services/smart";
import jetsafi from "@/services/jetsafi";
import spribe from "@/services/spribe";
import booming from "@/services/booming";
import shacksevo from "@/services/shacksevo";

import walletserve from "@/services/walletserve";

export default {
  name: "footballx",
  components: {
  },
  data: function () {
    return {
      loading: "",
      gameName: "",
      gameID: '',
      icon: "",
      isLoaded: false,
      launchURL: "",
      launchParameters: {},
      launchType: 1,
      isDemo: 0,

      amount: "",
    };

  },
  mounted() {

    this.$store.dispatch("setCurrentPage", "casinogames");
    this.reloadProfile();

  },
  computed: {

    app: function () {
      return this.$store.state.app;
    },


    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // react to route changes...
        this.gameName = to.params.gameName;
        this.gameID = to.params.gameID;
        this.launchParameters = to.params.launchParameters;
        this.launchType = to.params.launchType;
        this.isDemo = to.params.IsDemo;

        let payload = {
          provider_id: to.params.providerID,
          game_name: to.params.gameName,
          game_id: to.params.gameID,
          is_demo: to.params.isDemo,
        }

        this.getLaunchUrl(payload)

      },
    },
  },
  methods: {

    setAmount: function (amount) {
      this.amount = parseFloat(amount).toFixed(2);
    },

    deposit: function () {
      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.$toast.open({
          message: 'Please login to proceed',
          type: 'error',
          position: 'top'
        });
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 10) {
        this.$toast.open({
          message: 'Enter amount atleast 10 KSH or above',
          type: 'error',
          position: 'top'
        });
        return;
      }

      var vm = this;
      var path = "/deposit/initiate";

      var data = {
        amount: parseInt(this.amount),
      };

      // vm.loading = "loading";
      console.log(this.getAuth());

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.data;
          console.log(msg);
          // vm.setSuccess("Success", msg);
          this.$toast.open({
            message: 'Success',
            type: 'success',
            position: 'top'
          });
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              // vm.setError(
              //   "Session Expired",
              //   "Your session on this device has expired"
              // );
              this.$toast.open({
                                message: 'Your session on this device has expired',
                                type: 'error',
                                position: 'top'
                            });
              vm.logout();
              return;
            } else {
              this.$toast.open({
                message: err.response.data.error_message,
                type: 'error',
                position: 'top'
              });
              this.$toast.open({
                                message: err.response.data.error_message,
                                type: 'error',
                                position: 'top'
                            });
              // console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            // vm.setError(
            //   "Network Error",
            //   "Check your network connection and try again"
            // );
            this.$toast.open({
                                message: 'Check your network connection and try again',
                                type: 'error',
                                position: 'top'
                            });
            // console.log(JSON.stringify(err.request));
          } else {
            // vm.setError(
            //   "Network Error",
            //   "Check your network connection and try again three"
            // );
            console.log(JSON.stringify(err));
          }
        });
    },

    getLaunchUrl(data) {

      console.log('Launch ' + JSON.stringify(data, undefined, 2))

      if (!this.profile) {

        this.$toast.open({
          message: 'Please login to proceed',
          type: 'error',
          position: 'top'
        });
        this.setValue("placeBet", 3);
        this.$router.push({ name: "login", params: {} });
        return;
      }

      var providerId = data.provider_id;
      var gameId = data.game_id;
      var gameName = data.game_name;
      var accountID = parseInt(process.env.VUE_APP_CASINO_ACCOUNT_ID);

      var device_type = this.isMobile() ? "mobile" : "desktop";

      let payload = {};

      // Determine which endpoint and payload to use based on provider ID
      if (providerId === 1) {

        payload = {
          account_id: accountID,
          game_id: String(gameId),
          game_name: gameName,
          lobby_url: window.location.origin + '/casino',
          return_url: window.location.origin + '/casino',
          providerId: providerId,
        };

      } else if (providerId === 3) {

        payload = {
          account_id: parseInt(accountID),
          game_id: parseInt(gameId),
          game_name: gameName,
          device_type: String(device_type),
          lobby_url: window.location.origin + '/casino',
          return_url: window.location.origin + '/casino',
          providerId: providerId,
        };


      } else if (providerId === 5) {

        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          return_url: window.location.origin + '/casino',
          providerId: providerId,
        };


      } else if (providerId === 4) {

        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          lobby_url: window.location.origin + '/casino',
          return_url: window.location.origin + '/casino',
          providerId: providerId,
        };


      } else if (providerId === 6) {

        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          lobby_url: window.location.origin + '/casino',
          return_url: window.location.origin + '/casino',
          providerId: providerId,
        };


      } else if (providerId === 7) {

        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          lobby_url: window.location.origin + '/casino',
          return_url: window.location.origin + '/casino',
          providerId: providerId,
        };


      }
      else if (providerId === 8) {

        payload = {
          account_id: accountID,
          demo: 0,
          device: String(`Device ${device_type}`),
          game_id: String(gameId),
          game_name: gameName,
          lobby_url: window.location.origin + '/casino',
          return_url: window.location.origin + '/casino',
          providerId: providerId,
        };

      }
      else if (providerId === 9) {

        payload = {
          account_id: accountID,
          demo: 0,
          device: String(`Device ${device_type}`),
          game_id: String(gameId),
          game_name: gameName,
          return_url: window.location.origin + '/casino',
          lobby_url: window.location.origin + '/casino',
          providerId: providerId,
        };

      }
      else {
        console.error("Invalid providerId:", providerId);
        return; // Exit the function if providerId is invalid
      }

      console.log('provider ' + providerId + ' | payload ' + JSON.stringify(payload, undefined, 2))

      // Make API request with appropriate endpoint and payload
      return this.launchGame(payload); // Return the launch URL


    },

    launchGame: function (payload) {

      var vm = this;
      var providerId = payload.providerId;

      var service;

      switch (providerId) {
        case 1:
          service = prag;
          break;
        case 3:
          service = stp;
          break;
        case 4:
          service = elbet;
          break;
        case 5:
          service = smart;
          break;

        case 6:
          service = jetsafi;
          break;

        case 7:
          service = spribe;
          break;

        case 8:
          service = booming;
          break;

        case 9:
          service = shacksevo;
          break;

        default:
          console.error("Invalid providerId:", providerId);
          return; // Exit the function if providerId is invalid
      }

      service
        .post("/launch/url", payload, {
          headers: {
            "api-key": vm.getAuth(),
          }
        })
        .then(response => {

          vm.launchURL = response.data.game_url;
          console.log("LaunchURL", vm.launchURL)

          if (vm.launchType === "3") {

            window.open(this.launchURL);

          }

          console.log("LaunchUrl", vm.launchURL)
          this.isLoaded = true; // Set isLoaded to true after getting the launch URL
          return vm.launchURL;

        })
        .catch(error => {
          console.error("Error launching game:", error);
        });
    },

    goToCasino: function () {

      this.$router.push({ name: "casino", params: {} });

    },

    showDeposit: function () {

      document.getElementById("deposit-init").click();

    },

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },

  },
};
</script>

<style scoped>
.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--gray-white);

}

.list-active {
  background-color: #F08B05;
}

.title_name {
  color: #fff;
}

.parent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.parent .banner {
  width: 100%;
  /* height: 48px; */
}

.parent iframe {
  background: #000;
  border: none;
  flex-grow: 1;
}

h4 {
  position: relative;
  top: 0.6em;
  right: 1em;
}
</style>